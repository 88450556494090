import fetchData from './fetchData'
import {parseJSON, checkHttpStatus} from '../shared/utils'
import config from '../config/index'

async function login(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=auth`, 'POST', payload)
    .then(parseJSON)
    .then(checkHttpStatus);
}

async function configData(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=sellerread`, 'POST', payload)
      .then(parseJSON)
      .then(checkHttpStatus);
}

async function loginApiV2(payload) {
  return fetchData(`${config.api.baseUrlV2}cashbox/login`, 'POST', payload, true)
    .then(parseJSON)
    .then(checkHttpStatus);
}

async function loginByPointToken(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=authbypointtoken`, 'POST', payload)
    .then(parseJSON)
    .then(checkHttpStatus);
}

export {
  login,
  configData,
  loginApiV2,
  loginByPointToken
}