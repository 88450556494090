import React, {useState, useCallback, useEffect} from 'react';
import {WhiteSpace, Result, Icon, Button, Flex} from "antd-mobile-v2";
import {Link} from 'react-router-dom';

const ErrorScreen = ((props) => {

    return (
        <>
            <WhiteSpace />
            <Result
                img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                title="404"
                message={
                    <>
                        Запрашиваемая страница не была найдена
                        <WhiteSpace />
                        <Link to={`${process.env.PUBLIC_URL}`} align="center"><Button type="ghost">На главную</Button></Link>
                    </>
                }
            />
        </>
    )
});

export default ErrorScreen