import React, {useCallback, useState, useEffect}  from 'react';
import { Flex, Button, InputItem, WhiteSpace, Toast, Switch} from 'antd-mobile-v2';
import { createForm } from 'rc-form';
import {login as authLogin, loginApiV2, loginByPointToken} from '../../../api/auth';

const AuthForm = ((props) => {

    const [sessionId, setSessionId] = useState(
        localStorage.getItem('sessionId') || ''
    );

    const [tokenV2, setTokenV2] = useState(
        localStorage.getItem('tokenV2') || ''
    );

    const [userName, setUserName] = useState(
        localStorage.getItem('userName') || ''
    );

    const [sellerLogo, setSellerLogo] = useState(
        localStorage.getItem('sellerLogo') || ''
    );

    const [sellerName, setSellerName] = useState(
        localStorage.getItem('sellerName') || ''
    );

    const [point, setPoint] = useState(
        localStorage.getItem('point') || ''
    );

    const [token, setToken] = useState(
        localStorage.getItem('token') || ''
    );

    const [sellerIdnum, setSellerIdnum] = useState(
        localStorage.getItem('sellerIdnum') || ''
    );

    const [checkCode, setCheckCode] = useState(
        localStorage.getItem('checkCode') || ''
    );

    const [defaultGoods, setDefaultGoods] = useState(
      localStorage.getItem('defaultGoods') || ''
    );

    const { getFieldProps, getFieldError, validateFields } = props.form;
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [formValue, setFormValue] = useState({});
    const [useApiV2, setUseApiV2] = useState(false);

    useEffect(() => {
        localStorage.setItem('sessionId', sessionId);
        localStorage.setItem('userName', userName);
        localStorage.setItem('sellerName', sellerName);
        localStorage.setItem('sellerLogo', sellerLogo);
        localStorage.setItem('point', point);
        localStorage.setItem('token', token);
        localStorage.setItem('sellerIdnum', sellerIdnum);
        localStorage.setItem('checkCode', checkCode);
        localStorage.setItem('defaultGoods', defaultGoods)
        localStorage.setItem('tokenV2', tokenV2)
    }, [sessionId, userName, sellerName, sellerLogo, point, token, sellerIdnum, checkCode, defaultGoods, tokenV2]);

    useEffect(() => {
        localStorage.setItem('useApiV2', useApiV2);
    }, [useApiV2]);

    const sendForm = useCallback(() => {
        setLoadingRequest(true);
        validateFields((error, value) => {
            if (useApiV2) {
                value.username = value.login
                loginApiV2(value)
                .then(({data}) => {
                    const {token, point_token} = data;
                    setTokenV2(token);
                    // setSellerName(seller_name);
                    setUseApiV2(true)

                    loginByPointToken({token: point_token})
                    .then(({ result }) => {
                        let res = result[0];
                        setSessionId(res.s_token);
                        setSellerName(res.s_sellers);
                        setPoint(res.s_point);
                        setSellerLogo(res.s_logo);
                        setUserName(res.s_user);
                        setSellerIdnum(res.seller_idnum);
                        setCheckCode(res.n_check_code);
                        setDefaultGoods(res.default_good);
                        setToken(res.s_seller_token);

                        Toast.info('Авторизация прошла успешно', 1);
                        setLoadingRequest(false);

                        if (window.location.pathname !== `${process.env.PUBLIC_URL}/auth` && window.location.pathname !== process.env.PUBLIC_URL){
                            window.location.href = document.URL;
                        }else{
                            window.location.href = `${process.env.PUBLIC_URL}/`;
                        }
                    }).catch( (e) => {
                        console.log(e)
                        Toast.info('Ошибка авторизации', 1);
                        setLoadingRequest(false);
                    })
                })
                .catch( (e) => {
                    console.log(e)
                    Toast.info('Ошибка авторизации', 1);
                    setLoadingRequest(false);
                })
            } else {
                authLogin(value)
                    .then(({ result }) => {
                        let res = result[0];
                        setSessionId(res.s_token);
                        setSellerName(res.s_sellers);
                        setPoint(res.s_point);
                        setSellerLogo(res.s_logo);
                        setUserName(res.s_user);
                        setSellerIdnum(res.seller_idnum);
                        setCheckCode(res.n_check_code);
                        setDefaultGoods(res.default_good);
                        setToken(res.s_seller_token);

                        Toast.info('Авторизация прошла успешно', 1);
                        setLoadingRequest(false);

                        if (window.location.pathname !== `${process.env.PUBLIC_URL}/auth` && window.location.pathname !== process.env.PUBLIC_URL){
                            window.location.href = document.URL;
                        }else{
                            window.location.href = `${process.env.PUBLIC_URL}/`;
                        }
                    })
                    .catch( () => {
                        Toast.info('Ошибка авторизации', 1);
                        setLoadingRequest(false);
                    });
            }
        });
    }, [validateFields, useApiV2]);

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;

        if (keyCode === 13) {
        console.log('Press enter')
        sendForm();
        }
    }, [sendForm]);

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
        window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    return (
        <>
            <Flex justify="center">
                <img src="/Mb-1.png" alt="MaxBonus"/>
            </Flex>
            <WhiteSpace size="xl" />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <InputItem
                        {...getFieldProps('login', {
                            rules: [{required: true, message: 'Логин обязателен к заполнению'}],
                            onChange: e => setFormValue({...formValue, login: e})
                        })}
                        clear
                        placeholder="Введите логин"
                    />
                </Flex.Item>
            </Flex>
            {getFieldError('login')}
            <WhiteSpace />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <InputItem
                        {...getFieldProps('password', {
                            rules: [{required: true, message: 'Пароль обязателен к заполнению'}],
                            onChange: e => setFormValue({...formValue, password: e})
                        })}
                        clear
                        type="password"
                        placeholder="Введите пароль"
                    />
                </Flex.Item>
            </Flex>
            {getFieldError('password')}
            <WhiteSpace size="xl" />
            <Flex justify="center">
                <div className='label-mark'>Использовать apiv2</div>
                <Switch
                    checked={useApiV2}
                    {...getFieldProps('useApiV2', {
                        initialValue: useApiV2,
                    })}
                    color="coral"
                    onClick={(checked) => setUseApiV2(checked)}
                />
            </Flex>
            <WhiteSpace size="xl" />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <Button
                        disabled={loadingRequest || !formValue.login || !formValue.password}
                        loading={loadingRequest}
                        type="primary"
                        onClick={sendForm}
                    >
                        Войти
                    </Button>
                </Flex.Item>
            </Flex>
        </>
    )
});

export default createForm()(AuthForm);