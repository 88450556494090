import React, {useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import ruRU from 'antd-mobile-v2/lib/locale-provider/ru_RU';
import configureStore from './shared/redux/configureStore'
import 'antd-mobile-v2/dist/antd-mobile.css';
import './style.css';

import AuthScreen from './screens/Auth';
import MainScreen from "./screens/Main";
import ClientScreen from "./screens/Client";
import PhoneScreen from "./screens/Find/screens/Phone";
import QrScreen from "./screens/Find/screens/Qr";
import AddGoods from "./screens/Client/screens/Goods";
import Return from "./screens/Client/screens/Return";
import CreateCheck from "./screens/Client/screens/Goods/screens/Check";
import ErrorScreen from "./screens/Error";
import withAuth from "./shared/hoc/withAuth";
import withClient from "./shared/hoc/withClient";
import {LocaleProvider} from "antd-mobile-v2";

const store = configureStore();

function App() {

  return (
      <>
        <Provider store={store}>
            <LocaleProvider locale={ruRU}>
              <BrowserRouter>
                <Switch>
                  <Route exact path={`${process.env.PUBLIC_URL}/auth`} component={AuthScreen} />
                  <Route exact path="/" component={withAuth(MainScreen)} />
                  <Route exact path={`${process.env.PUBLIC_URL}/findPhone`} component={withAuth(PhoneScreen)}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/findQr`} component={withAuth(QrScreen)}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/client`} component={withClient(ClientScreen)}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/client/addGoods`} component={withClient(AddGoods)}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/client/return`} component={withClient(Return)}/>
                  <Route path={`${process.env.PUBLIC_URL}/client/check`} component={withClient(CreateCheck)}/>
                  <Route component={ErrorScreen} />
                </Switch>
              </BrowserRouter>
            </LocaleProvider>
        </Provider>
      </>
  );
}

export default App
