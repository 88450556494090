import fetchData from './fetchData'
import {parseJSON, checkHttpStatus} from '../shared/utils'
import config from '../config/index'

async function findUser(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=status`, 'POST', payload)
    .then(parseJSON)
    .then(checkHttpStatus);
}

async function findUserApiV2(phone, payload) {
  return fetchData(`${config.api.baseUrlV2}cashbox/customer/auth?phone=${phone}`, 'POST', payload, true)
    .then(parseJSON)
    .then(checkHttpStatus);
}

async function sendCode(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=askpin`, 'POST', payload)
      .then(parseJSON)
      .then(checkHttpStatus);
}

async function checkCode(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=checkpin`, 'POST', payload)
      .then(parseJSON)
      .then(checkHttpStatus);
}

async function findUserQr(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=customer`, 'POST', payload)
      .then(parseJSON)
      .then(checkHttpStatus);
}

async function findUserQrApiV2(code, payload) {
  return fetchData(`${config.api.baseUrlV2}cashbox/customer/authqr?qrcode=${code}`, 'POST', payload, true)
      .then(parseJSON)
      .then(checkHttpStatus);
}

export {
  findUser,
  findUserApiV2,
  sendCode,
  checkCode,
  findUserQr,
  findUserQrApiV2
}