import {createReducer} from "../utils";


const CALL_ERROR = 'app/error/CALL_ERROR';
const CLEAR_ERROR = 'app/error/CLEAR_ERROR';

// reducer

export function callError(code, text) {
    return {
        type: CALL_ERROR,
        payload: {
            code: code,
            text: text
        }
    }
}

export function clearError() {
    return {
        type: CLEAR_ERROR,
        payload: {
            code: null,
            text: null
        }
    }
}

const initialState = {
    code: null,
    text: null,
};

export default createReducer(initialState, {
    [CALL_ERROR]: (state, payload) => {
        return {
            ...state,
            code: payload.code,
            text: payload.text
        }
    },
    [CLEAR_ERROR]: (state) => {
        return {
            ...state,
            code: null,
            text: null,
        }
    }
});