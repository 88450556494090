import fetchData from './fetchData'
import {parseJSON, checkHttpStatus} from '../shared/utils'
import config from '../config/index'

async function getTopCategory(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=categorytop`, 'POST', payload)
    .then(parseJSON)
    .then(checkHttpStatus);
}

async function getCategoryGoods(categoryId, filter, payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=categorychild&idnum=${categoryId}&filter=${filter}`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function addGoods(payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=addgoods`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

export {
    getTopCategory,
    getCategoryGoods,
    addGoods
}