import React, {useState, useCallback, useEffect, useMemo} from 'react';
import MainNavBar from '../../../../shared/components/NavBar';
import {
    Button,
    Flex,
    InputItem,
    Modal,
    WhiteSpace,
    Badge,
    PullToRefresh, Toast, SearchBar,
} from "antd-mobile-v2";
import QueueAnim from 'rc-queue-anim';
import { createForm } from 'rc-form';
import {getTopCategory} from "../../../../api/goods";
import SubAccordion from './components/SubAccordion';
import {Link} from "react-router-dom";

const INITIAL_FORM_VALUES = {
    count: '1',
    mrc: '0',
    price: ''
}

const AddGoodsScreen = ((props) => {

    const [goods, setGoods] = useState(props.location.state.goods);
    const [showModal, setShowModal] = useState(false);
    const [activeElem, setActiveElem] = useState({});
    const [formValue, setFormValue] = useState(INITIAL_FORM_VALUES);
    const [filter, setFilter] = useState('');
    const [appendedFilter, setAppendedFilter] = useState('');
    const [listGoods, setListGoods] = useState({0: []});

    const { getFieldProps, getFieldError } = props.form;

    const changeGoods = useCallback((currentGoods, goodCategory, checked) => {
        if (checked) {
            setGoods({...goods, [currentGoods.idnum] : currentGoods});
            setActiveElem(
                {
                    currentGoods: currentGoods,
                    count: '1'
                });
            setShowModal(true);
        }else{
            setActiveElem('');
            setGoods({...goods, [currentGoods.idnum] : null})
        }
        setFormValue(INITIAL_FORM_VALUES);
    },[goods]);

    const removeGood = useCallback((e) => {
        setShowModal(false);
        changeGoods(e.currentGoods, e.category, false)
    },[changeGoods]);

    const changeFilter = useCallback((e) => {
        setFilter(e)
    },[filter]);

    const onPriceChange = useCallback(price => {
        setFormValue({...formValue, price})
    }, [formValue])

    const onMRCChange = useCallback(mrc => {
        setFormValue({...formValue, mrc})
    }, [formValue])

    const onCountChange = useCallback(count => {
        setActiveElem({...activeElem, count});
        setFormValue({...formValue, count});
    },[formValue, activeElem]);

    const increaseCount = useCallback(e => {
        let newCount = Number(formValue.count) + e;
        if (newCount > 0) {
            onCountChange(newCount.toString());
        }
    }, [formValue.count, onCountChange])

    const isSubmitButtonDisabled = useMemo(() => {
        const {mrc, count, price} = formValue;
        const isMrcValid = !!mrc.length && Number(mrc) >= 0;
        const isCountValid = !!count.length && Number(count) > 0;
        const isPriceValid = !!price.length && Number(price) >= 0;

        return !(isMrcValid && isCountValid && isPriceValid);
    }, [formValue])

    useEffect(() => {
      
        const timeOutId = setTimeout(() => {
            setAppendedFilter(filter)
            Toast.loading('', 3)
            if (appendedFilter == filter) {
                Toast.hide()
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
        
        // if (1===2) {
        //     const timer = setTimeout(() => getTopCategory(filter)
        //     .then(({ result }) => {
        //         setListGoods({...listGoods, 0: result});
        //         Toast.hide();
        //     })
        //     .catch( () => {
        //         Toast.info('Ошибка обновления информации', 1);
        //         Toast.hide();
        //     }), 1000);

        //     return () => clearTimeout(timer);
        // }

    },[filter]);

    useEffect(() => {
        activeElem.currentGoods && setGoods({
            ...goods,
            [activeElem.currentGoods.idnum]: {
                ...goods[activeElem.currentGoods.idnum],
                mrc: Number(formValue.mrc),
                price: Number(formValue.price),
                count: Number(formValue.count)
            }
        })
    },[formValue, activeElem]);

    return (
        <>
            <MainNavBar {...props} backWay='client' goods={goods} text="Добавить товар"/>
            <QueueAnim delay={300} className="queue-simple">
            <PullToRefresh
                damping={60}
                style={{
                    overflow: 'auto',
                }}
                indicator={{ activate: 'Отпустите для обновления', finish: 'Данные обновлены' }}
                direction='down'
                onRefresh={() => {
                    console.log('has refreshed')
                }}
            >
                <SearchBar key='a' placeholder="Поиск" onChange={e => (e.length > 2 || e.length === 0) && changeFilter(e)} maxLength={50}  />
            </PullToRefresh>

            <SubAccordion
                {...props}
                goods={goods}
                listGoods={listGoods}
                changeGoods={changeGoods}
                filter={appendedFilter}
                myActiveElement={0}
                idnum={0}
                name='Все товары'
            />

            <Modal
                animationType="slide-up"
                popup
                maskClosable={false}
                visible={showModal}
                transparent
                onClose={() => setShowModal(false)}
            >

                <Flex direction="column" justify="start" alignContent="start" align="start">
                    {activeElem.currentGoods &&
                        <>
                            <Flex.Item className='main-card-text'>
                                {activeElem.currentGoods.s_name}
                            </Flex.Item>
                            <WhiteSpace />
                            <Flex.Item style={{width: '100%'}}>
                                <Flex justify="between" alignContent="start" align="start">
                                    <Flex style={{flex: 2}} direction="column">
                                        <Flex.Item style={{width: '100%'}}>
                                            Введите цену товара
                                            <InputItem
                                                {...getFieldProps('price', {
                                                    rules: [{required: true, message: 'Цена обязательна к заполнению'}],
                                                    onChange: onPriceChange
                                                })}
                                                value={formValue.price}
                                                clear
                                                type="digit"
                                                extra="₽"
                                            />
                                        </Flex.Item>
                                        <Flex.Item style={{width: '100%', marginTop: 20}}>
                                            Введите МРЦ
                                            <InputItem
                                                onChange={onMRCChange}
                                                value={formValue.mrc}
                                                clear
                                                type="digit"
                                                extra="₽"
                                            />
                                        </Flex.Item>
                                    </Flex>
                                    <Flex.Item style={{flex: 1, marginLeft: 20}}>
                                        <Flex direction="column" justify="center" alignContent="center" align="center">
                                            <Flex.Item>
                                                <Button onClick={() => increaseCount(1)} className="icon-button" type="primary" icon="plus" inline size="small"/>
                                            </Flex.Item>
                                            <Flex.Item style={{maxWidth: 175, marginTop: 10, marginBottom: 20}}>
                                                <InputItem
                                                    onChange={onCountChange}
                                                    value={formValue.count}
                                                    clear
                                                    type="digit"
                                                    extra="шт."
                                                />
                                            </Flex.Item>
                                            <Flex.Item>
                                                <Button disabled={activeElem.count <= 1 && true} onClick={() => increaseCount(-1)} className="icon-button" type="primary" icon="minus" inline size="small"/>
                                            </Flex.Item>
                                        </Flex>
                                    </Flex.Item>
                                </Flex>
                            </Flex.Item>
                            {getFieldError('price')}
                            <WhiteSpace style={{paddingTop: 50}}  />
                            <Flex justify="center" wrap="wrap" style={{width: '100%'}}>
                                <Flex.Item>
                                    <Button onClick={() => removeGood(activeElem)}>
                                        Отмена
                                    </Button>
                                </Flex.Item>
                                <Flex.Item>
                                    <Button
                                        disabled={isSubmitButtonDisabled}
                                        onClick={() => setShowModal(false)}
                                    >
                                        Принять
                                    </Button>
                                </Flex.Item>
                            </Flex>
                        </>
                    }
                </Flex>
            </Modal>

            {Boolean(Object.values(goods).filter(element => element !== null).length) &&
            <div className="action-button">
                <Badge text={Object.values(goods).filter(element => element !== null).length}>
                    <Link to={{
                        pathname: `${process.env.PUBLIC_URL}/client`,
                        state: {goods}
                    }}>
                        <img alt="Добавить в корзину" className="ant-menu-item" src="/img/cart.svg"/>
                    </Link>
                </Badge>
            </div>
            }
            </QueueAnim>
        </>
    )
});

export default createForm()(AddGoodsScreen)
