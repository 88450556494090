import React, {useCallback} from 'react';
import { NavBar, Icon} from 'antd-mobile-v2';
import QueueAnim from "rc-queue-anim";

const MainNavBar = ((props) => {

    const {text, backWay, goods} = props;

    const pathTo = useCallback(() => {
        const {history} = props;
        history.push({
            pathname: `${process.env.PUBLIC_URL}/${backWay === 'main' ? '' : backWay}`,
            state: goods && {goods: goods}
        });
    },[props, backWay, goods]);

    return (
        <QueueAnim
            delay={300}
            className="queue-simple"
            animConfig={[
                { opacity: [1, 0], translateY: [0, -50] },
                { opacity: [1, 0], translateY: [0, 50] }
            ]}
        >
            <NavBar
                key='a'
                className="main-nav-bar"
                mode="light"
                icon={<Icon type="left" />}
                onLeftClick={() => backWay ? pathTo() : window.history.back()}
            >
                {text}
            </NavBar>
        </QueueAnim>
    )
});

export default MainNavBar