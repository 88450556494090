import React, {useState, useCallback, useEffect} from 'react';

import {
    Button,
    Flex,
    InputItem,
    Modal,
    Toast,
    WhiteSpace,
    Switch
} from "antd-mobile-v2";

import {addGoods} from "../../../../../api/goods";

const AddGoodsBtn = ((props) => {

    const {elementId, getListGoods} = props;
    const emptyGoods = {
        name: "",
        identity: "",
        category: elementId,
        isDiscount: true
    }

    const [showModal, setShowModal] = useState(false);
    const [newGoods, setNewGoods] = useState(emptyGoods);
    const [isLoading, setIsLoading] = useState(false);

    const addNewGoods = useCallback(() => {
        setIsLoading(true)
        addGoods(newGoods)
                .then(({result}) => {
                    Toast.hide();
                    setIsLoading(false)
                    setShowModal(false)
                    setNewGoods(emptyGoods)
                    getListGoods()
                }).catch((e) => {
                    setIsLoading(false)
                    Toast.info('Ошибка добавления товара', 1);
                    // Toast.hide();
                })
    },[newGoods]);

    return (
        <>
            <Button color='primary' fill='none' inline className="add-goods-btn" size='small' onClick={() => setShowModal(true)}>
                Добавить новый товар в категорию
            </Button>

            <Modal
                animationType="slide-up"
                popup
                visible={showModal}
                transparent
                onClose={() => setShowModal(false)}
                maskClosable={!isLoading}
            >
                <Flex direction="column" justify="start" alignContent="start" align="start">
                <Flex.Item style={{width: 200}}>
                    Название товара
                    <InputItem
                        onChange={(e) => setNewGoods({...newGoods,name: e})}
                        clear
                        defaultValue={newGoods.name}
                    />
                </Flex.Item>
                <WhiteSpace style={{paddingTop: 30}}  />
                <Flex.Item style={{width: 200}}>
                    Идентификатор
                    <InputItem
                        onChange={(e) => setNewGoods({...newGoods,identity: e})}
                        clear
                        defaultValue={newGoods.identity}
                    />
                </Flex.Item>
                <WhiteSpace style={{paddingTop: 30}}  />
                <Flex.Item style={{width: 200}}>
                    Аукционность
                    <WhiteSpace style={{paddingTop: 10}}  />
                    <Switch 
                        color="coral"
                        onChange={(e) => setNewGoods({...newGoods,isDiscount: e})}
                        checked={newGoods.isDiscount}
                    />
                </Flex.Item>
                <WhiteSpace style={{paddingTop: 80}}  />
                <Flex justify="center" wrap="wrap" style={{width: '100%'}}>
                    <Flex.Item>
                        <Button
                            disabled={isLoading}
                            onClick={() => setShowModal(false)}
                        >
                            Отмена
                        </Button>
                    </Flex.Item>
                    <Flex.Item>
                        <Button
                            loading={isLoading}
                            disabled={isLoading || newGoods.name.length === 0 || newGoods.identity.length === 0}
                            type="primary"
                            onClick={() => addNewGoods()}
                        >
                            Добавить
                        </Button>
                    </Flex.Item>
                </Flex>
                </Flex>
            </Modal>
        </>
    )
});

export default AddGoodsBtn