export default function fetchData15(url, method = 'GET', body = null) {
  let config = {
    method,
    headers: {}
  };

  if (!body) body = {};

  const sessionId = localStorage.getItem('sessionId');
  if (sessionId) {
    body['sessionID'] = sessionId;
    //config.headers['Authorization'] = `${ sessionId }`;
  }

  //config.body = JSON.stringify(body); Perfect send

  if (body) {
    config.body = JSON.stringify(body.data);
  }

  //config.body = Object.entries(body).map(e => e.join('=')).join('&');

  return fetch(url, config);
}