import {createReducer} from "../utils";


const GOODS_CATEGORY = 'goodsCategory';

// actions

const GET_CONFIG_REQUEST = 'app/config/GET_LIST_CONFIG_REQUEST';
const GET_CONFIG_SUCCESS = 'app/config/GET_LIST_CONFIG_SUCCESS';
const GET_CONFIG_FAILURE = 'app/config/GET_LIST_CONFIG_FAILURE';

// action creators

export function configRequest() {
  return {
    type: GET_CONFIG_REQUEST
  }
}

export function configSuccess(res, type) {
  let obj = {};
  obj[type] = res;
  return {
    type: GET_CONFIG_SUCCESS,
    payload: obj[type],
  }
}

export function configFailure(error) {
  return {
    type: GET_CONFIG_FAILURE
  }
}

/*
export function getConfig() {
  return (dispatch) => {
    dispatch(configRequest());
    return getGoodsType()
        .then(response => {
          try {
            const { result } = response;
            dispatch(configSuccess(result, GOODS_CATEGORY));
          } catch (e) {
            dispatch(configFailure(e));
          }
        })
        .catch(e => {
          dispatch(configFailure(e));
          throw e;
        })
  }
}
 */

export function getConfig() {
  return (dispatch) => {
    dispatch(configRequest());
    dispatch(configSuccess(1, GOODS_CATEGORY));
  }
}

// reducer
const initialState = {
  actionTypes: [
    {
      id: 1,
      title: '1'
    },
    {
      id: 2,
      title: '2'
    },
    {
      id: 3,
      title: '3'
    },
    {
      id: 4,
      title: '4'
    },
    {
      id: 5,
      title: '5'
    }
  ],
};

export default createReducer(initialState, {
  [GET_CONFIG_REQUEST]: (state, payload) => {
    return state;
  },
  [GET_CONFIG_SUCCESS]: (state, payload) => {
    return {
      ...state,
      config: payload,
    }
  },
  [GET_CONFIG_FAILURE]: (state, payload) => {
    return {
      ...state,
      config: null,
    }
  }
});
