import {combineReducers} from 'redux';

import config from './config';
import error from './error';

const rootReducer = combineReducers({
  error,
  config
});

export default rootReducer;