import React, {useCallback, useEffect, useState} from 'react';
import { Flex, Button, InputItem, WhiteSpace, Toast} from 'antd-mobile-v2';
import { createForm } from 'rc-form';
import {checkCode, sendCode as sendVerifyCode} from '../../../../../api/find';
import RICIBs from 'react-individual-character-input-boxes';

const CodeForm = ((props) => {

    const [loadingRequest, setLoadingRequest] = useState(false);
    const [formValue, setFormValue] = useState({pin: ''});

    const {client} = props;

    const sendForm = useCallback(() => {
        setLoadingRequest(true);
        formValue.pin.length === 5 &&
            checkCode({...client, pin: formValue.pin})
                .then(() => {
                    Toast.info('Код успешно подтвержден', 1);
                    localStorage.setItem('client', JSON.stringify(client));
                    setLoadingRequest(false);
                    window.location.href = `${process.env.PUBLIC_URL}/client`;
                })
                .catch( () => {
                    Toast.info('Ошибка подтверждения кода', 1);
                    setLoadingRequest(false);
                });

    }, [formValue, client]);

    const handleOutput = useCallback((e) => {
        setFormValue({pin: e})
    },[]);

    const sendCodeAgain = useCallback(() => {
        sendVerifyCode(client)
            .then(() => {
                Toast.info('Код успешно отправлен', 1);
                setLoadingRequest(false);
            })
            .catch( () => {
                Toast.info('Ошибка отправки кода', 1);
                setLoadingRequest(false);
            });
    },[client]);

  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event;

    if (keyCode === 13) {
      console.log('Press enter');
      (!loadingRequest && formValue.pin.length === 5) && sendForm();
    }
  }, [loadingRequest, formValue]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

    return (
        <>
            <WhiteSpace size="xl" />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <RICIBs
                        amount={5}
                        handleOutputString={e => handleOutput(e)}
                        inputRegExp={/^[0-9]$/}
                    />
                </Flex.Item>
            </Flex>
            <WhiteSpace size="md" />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <Button
                        disabled={loadingRequest || formValue.pin.length !== 5}
                        loading={loadingRequest}
                        type="primary"
                        onClick={sendForm}
                    >
                        Продолжить
                    </Button>
                </Flex.Item>
            </Flex>
            <WhiteSpace size="md" />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <Button
                        onClick={sendCodeAgain}
                        type="ghost"
                    >
                        Отправить код повторно
                    </Button>
                </Flex.Item>
            </Flex>
        </>
    )
});

export default createForm()(CodeForm);