export default function fetchData(url, method = 'GET', body = null, useApiV2 = false) {
  let config = {
    method,
    headers: useApiV2 ? {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('tokenV2')}`,
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      // 'Content-Length': '52098',
      // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    } : {}
  };

  if (!body) body = {};

  const sessionId = localStorage.getItem('sessionId');
  if (sessionId) {
    body['sessionID'] = sessionId;
    //config.headers['Authorization'] = `${ sessionId }`;
  }

  if (useApiV2) {
    config.body = JSON.stringify(body)
  } else {
    config.body = Object.entries(body).map(e => e.join('=')).join('&');
  }

  return fetch(url, config);
}