import React from 'react';
import Form from './components/AuthForm'

const AuthScreen = ((props) => {

    return (
        <div className="flex-container auth">
            <Form
                {...props}
            />
        </div>
    )
});

export default AuthScreen