import React, {useState, useEffect} from 'react';
import MainNavBar from '../../../../shared/components/NavBar';
import FormPhone from "../Phone/components/PhoneForm";
import CodeForm from "../Phone/components/CodeForm";

const FindPhoneScreen = ((props) => {

    const [client, setClient] = useState('');

    useEffect(() => {
        setClient(props.location.state ? props.location.state.client : '')
    },[props.location.state]);

    return (
        <>
            <MainNavBar text="По номеру телефона"/>
            <div className="flex-container auth">
                {client
                    ?
                    <>
                        <div className="title-block">Введите код, который был отправлен на номер <br/><span style={{color: '#F4743D'}}>{client.s_phone}</span></div>
                        <CodeForm
                            {...props}
                            client={client}
                        />
                    </>
                    :
                    <>
                        <div className="title-block">Спросите клиента его номер телефона</div>
                        <FormPhone
                            {...props}
                        />
                    </>
                }
            </div>
        </>
    )
});

export default FindPhoneScreen