import React, {useState, useCallback, useEffect} from 'react';

import {
    Accordion,
    Checkbox,
    Toast
} from "antd-mobile-v2";

import {getCategoryGoods, getTopCategory} from "../../../../../api/goods";
import AddGoodsBtn from './AddGoodsBtn';

const CheckboxItem = Checkbox.CheckboxItem;

const SubAccordion = ((props) => {

    const {idnum, name, goods, changeGoods, filter} = props;

    const [listGoods, setListGoods] = useState(props.listGoods);
    const [myActiveElement, setMyActiveElement] = useState(props.myActiveElement);

    useEffect(() => {
        if (idnum === 0){
            const timer = setTimeout(() => getTopCategory({filter:filter})
                .then(({result}) => {
                    setListGoods({...listGoods, 0: result});

                    Toast.hide();
                })
                .catch(() => {
                    Toast.info('Ошибка обновления информации', 1);
                    Toast.hide();
                }), 1000)

            return () => clearTimeout(timer);

        }
        myActiveElement === idnum && getListGoods(idnum)
    }, [filter, idnum]);

    const activeElement = useCallback((categoryId) => {
        getListGoods(categoryId);
        setMyActiveElement(categoryId)
    },[listGoods, idnum, filter]);

    const getListGoods = useCallback((categoryId) => {

        !listGoods[idnum] && Toast.loading('', 30);

        const timer = setTimeout(() => getCategoryGoods(idnum, filter)
            .then(({ result }) => {
                if (result.length > 0) {
                    setListGoods({...listGoods, [idnum]: result})
                }
                Toast.hide();
            })
            .catch( () => {
                Toast.info('Ошибка получения товаров', 1);
                Toast.hide();
            }), 500)

        return () => clearTimeout(timer);

    },[listGoods, idnum, filter]);

    return (
        <Accordion defaultActiveKey="0" key={idnum} accordion className={idnum !== 0 ? 'accordion-child' : ''} onChange={e => e && activeElement(e)}>
            <Accordion.Panel key={idnum} header={name}>
                <AddGoodsBtn elementId={idnum} getListGoods={getListGoods}/>
                {listGoods[idnum] &&
                    listGoods[idnum].map(i => (
                        Number(i.is_goods) === 1 ?
                            <CheckboxItem
                                checked={Boolean(goods[i.idnum])}
                                key={i.idnum}
                                onChange={(e) => changeGoods(i, i, e.target.checked)}
                            >
                                {i.s_name}
                            </CheckboxItem>

                        :
                        <SubAccordion
                            {...props}
                            key={i.idnum}
                            listGoods={listGoods}
                            changeGoods={changeGoods}
                            filter={filter}
                            goods={goods}
                            idnum={i.idnum}
                            myActiveElement={myActiveElement}
                            name={i.s_name}
                        />
                        )
                    )
                }
            </Accordion.Panel>
        </Accordion>
    )
});

export default SubAccordion