import React from 'react';
import AuthScreen from '../../screens/Auth';

export default function withAuth(Component) {

  if (localStorage.getItem('sessionId')) {
      return Component;
  }else{
      return AuthScreen;
  }
}


