import fetchData from './fetchData'
import {parseJSON, checkHttpStatus} from '../shared/utils'
import config from '../config/index'
import fetchData15 from './fetchData15';

async function createCheck(payload) {
  return fetchData(`${config.api.baseUrl}?class=check&action=calc`, 'POST', payload)
    .then(parseJSON)
    .then(checkHttpStatus);
}

async function createCheck15(payload) {
    return fetchData15(`${config.api.baseUrl}?class=check&action=calcv1.5`, 'POST', payload)
      .then(parseJSON)
      .then(checkHttpStatus);
}

async function writeCheck(payload) {
    return fetchData(`${config.api.baseUrl}?class=check&action=write`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function addBonus(payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=add`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function subBonus(payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=sub`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function checkList(payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=checklist`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function checkDetail(payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=checkdetailwr`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function returnCheck(payload) {
    return fetchData(`${config.api.baseUrl}?class=check&action=return`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function createCheckV2(payload) {
    return fetchData(`${config.api.baseUrlV2}cashbox/check`, 'POST', payload, true)
      .then(parseJSON)
      .then(checkHttpStatus);
}

async function writeCheckV2(payload) {
    return fetchData(`${config.api.baseUrlV2}cashbox/check/write`, 'POST', payload, true)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function returnCheckV2(payload) {
    return fetchData(`${config.api.baseUrlV2}cashbox/check/return`, 'POST', payload, true)
        .then(parseJSON)
        .then(checkHttpStatus);
}

export {
    createCheck,
    createCheck15,
    writeCheck,
    addBonus,
    subBonus,
    checkList,
    checkDetail,
    returnCheck,
    createCheckV2,
    writeCheckV2,
    returnCheckV2
}