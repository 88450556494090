import React from 'react';
import AuthScreen from '../../screens/Auth';
import MainScreen from '../../screens/Main';

export default function withClient(Component) {

  if (!localStorage.getItem('sessionId')) {
      return AuthScreen;
  }else if (!localStorage.getItem('client')){
      return MainScreen;
  }else{
      return Component;
  }
}


