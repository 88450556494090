import React, {useCallback, useEffect, useState} from 'react';
import { Flex, Button, InputItem, WhiteSpace, Toast} from 'antd-mobile-v2';
import { createForm } from 'rc-form';
import { AsYouType  } from 'libphonenumber-js';
import {findUser, findUserApiV2, sendCode as sendVerifyCode} from '../../../../../api/find';

const PhoneForm = ((props) => {

    const needVerify = localStorage.getItem('checkCode') === '1';
    const useApiV2 = localStorage.getItem('useApiV2') === 'true';

    const { getFieldProps, getFieldError, validateFields } = props.form;
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [formValue, setFormValue] = useState({phone: '+7'});
    const [validate, setValidate] = useState(false);

    const sendCode = useCallback((e) => {
        const {history} = props;
        history.push({
            pathname: `${process.env.PUBLIC_URL}/findPhone`,
            state: {client: e}
        });
    },[props]);

    const sendForm = useCallback(() => {
        setLoadingRequest(true);
        validateFields((error, value) => {
            useApiV2 
            ?
                findUserApiV2(value.phone,value) .then(( {data} ) => {
                    console.log('Client is find!')
                    // console.log(data)
                    const client = {
                        s_phone: data.phone,
                        is_chatbot: !!data.exists_chatbot ? 1: 0,
                        s_name1: data.last_name,
                        s_name2: data.first_name,
                        s_name3: data.middle_name,
                        d_birthday: "", // Др нет
                        n_balance: data.total_bonus,
                        s_loyalty_level: 0, // Тоже нет
                        s_cashier_info: data.cashier_info
                    }
             
                    setLoadingRequest(false);
                    Toast.info('Код успешно подтвержден', 1);
                    localStorage.setItem('client', JSON.stringify(client));
                    window.location.href = `${process.env.PUBLIC_URL}/client`;
                })
                .catch( () => {
                    Toast.info('Ошибка отправки кода', 1);
                    setLoadingRequest(false);
                })
            :
                findUser(value)
                    .then(({ result }) => {
                        let res = result[0];
                        console.log('Client is find!')
                        setLoadingRequest(false);

                        let needVerify = false;
                        if (needVerify) {
                            sendVerifyCode(res)
                                .then(() => {
                                    Toast.info('Код успешно отправлен', 1);
                                    setLoadingRequest(false);
                                    sendCode(res)
                                })
                                .catch(() => {
                                    Toast.info('Ошибка отправки кода', 1);
                                    setLoadingRequest(false);
                                })
                        }else{
                            Toast.info('Код успешно подтвержден', 1);
                            localStorage.setItem('client', JSON.stringify(res));
                            window.location.href = `${process.env.PUBLIC_URL}/client`;
                        }
                    })
                    .catch( () => {
                        Toast.info('Ошибка отправки кода', 1);
                        setLoadingRequest(false);
                    });
        });
    }, [validateFields, sendCode]);


    const validatePhoneNumber = useCallback((e) => {

        let phoneFormatted = new AsYouType('RU');
        let finishPhoneString = phoneFormatted.input(e);

        setValidate(Boolean(phoneFormatted.getTemplate()));

        if ((e.charAt(0) === '+' && e.charAt(1) === '7') || e.length === 0) {
            setFormValue({...formValue, phone: finishPhoneString})
        } else if (e.length === 1) {
            finishPhoneString = '+7' + finishPhoneString.substring(1);
            setFormValue({...formValue, phone: finishPhoneString})
        } else {
            setFormValue({...formValue, phone: finishPhoneString})
        }

        setFormValue({...formValue, phone: finishPhoneString})

    },[formValue]);

  const handleUserKeyPress = useCallback(event => {
    const { keyCode } = event;

    if (keyCode === 13) {
      console.log('Press enter', !loadingRequest , Boolean(formValue.phone.length === 16) , validate);
      (!loadingRequest && Boolean(formValue.phone.length === 16) && validate) && sendForm();
    }
  }, [loadingRequest, formValue, validate, sendForm]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

    return (
        <>
            <Flex justify="center">
                <img src="/img/findPhone.png" alt="MaxBonus"/>
            </Flex>
            <WhiteSpace size="xl" />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <InputItem
                        {...getFieldProps('phone', {
                            rules: [{required: true, max: 16, message: 'Телефон обязателен к заполнению'}],
                            onChange: e => validatePhoneNumber(e)
                        })}
                        clear
                        value={formValue.phone}
                        maxLength={16}
                        type="tel"
                        placeholder="Введите номер телефона"
                    />
                </Flex.Item>
            </Flex>
            {getFieldError('phone')}
            <WhiteSpace size="xl" />
            <Flex justify="center" wrap="wrap">
                <Flex.Item>
                    <Button
                        disabled={loadingRequest || !Boolean(formValue.phone.length === 16) || !validate }
                        loading={loadingRequest}
                        type="primary"
                        onClick={sendForm}
                    >
                        {needVerify ? 'Отправить код' : 'Войти'}
                    </Button>
                </Flex.Item>
            </Flex>
        </>
    )
});

export default createForm()(PhoneForm);