import rootReducer from './index';
import thunk from 'redux-thunk';
import {applyMiddleware, createStore} from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { createLogger } from 'redux-logger'
import {loadState, saveState} from "./localStorage"
import throttle from 'lodash/throttle';

const configureStore = () => {
  const logger = createLogger();
  const store = createStore(
    rootReducer,
    loadState(),
    applyMiddleware(thunk, apiMiddleware, logger),
  );

  store.subscribe(throttle(() => saveState({
    auth: store.getState().auth
  }), 1000));
  return store;

};

export default configureStore;
